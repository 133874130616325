<template>
  <g>
    <svg:style>
      .cls-1,
      .cls-10,
      .cls-2,
      .cls-3,
      .cls-4,
      .cls-5,
      .cls-6,
      .cls-7,
      .cls-8,
      .cls-9 {
        stroke: #aeaeae;
        stroke-miterlimit: 10;
        stroke-width: 0.25px;
      }

      .cls-1 {
        fill: url(#BG-gradient);
      }

      .cls-10,
      .cls-2 {
        fill: none;
      }

      .cls-2 {
        stroke-linecap: square;
      }

      .cls-3 {
        fill: url(#handle-gradient);
      }

      .cls-4 {
        fill: url(#inox-gradient);
      }

      .cls-5 {
        fill: url(#glass-gradient);
      }

      .cls-6 {
        fill: url(#inox-gradient-2);
      }

      .cls-7 {
        fill: url(#inox-gradient-3);
      }

      .cls-8 {
        fill: url(#inox-gradient-4);
      }

      .cls-9 {
        fill: url(#inox-gradient-5);
      }
    </svg:style>
    <linearGradient
      id="BG-gradient"
      x1="0.13"
      y1="147.13"
      x2="140.13"
      y2="147.13"
      gradientUnits="userSpaceOnUse"
    >
      <stop offset="0.25" stop-color="#f9f9f9" />
      <stop offset="0.67" stop-color="#e8e8e8" />
      <stop offset="0.79" stop-color="#e2e2e2" />
    </linearGradient>
    <linearGradient
      id="handle-gradient"
      x1="11.11"
      y1="150.9"
      x2="16.82"
      y2="150.9"
      gradientUnits="userSpaceOnUse"
    >
      <stop offset="0.01" stop-color="#babbbc" />
      <stop offset="0.29" stop-color="#989a9e" />
      <stop offset="0.65" stop-color="#bdbdc1" />
      <stop offset="0.87" stop-color="#9d9fa2" />
      <stop offset="1" stop-color="#a6a8aa" />
      <stop offset="1" stop-color="#c2c5c9" />
    </linearGradient>
    <linearGradient
      id="inox-gradient"
      x1="45.65"
      :y1="inoxY"
      x2="45.65"
      :y2="inoxY + inoxH"
      xlink:href="#handle-gradient"
    />
    <linearGradient
      id="glass-gradient"
      x1="39.93"
      y1="148.61"
      x2="51.14"
      y2="148.61"
      gradientUnits="userSpaceOnUse"
    >
      <stop offset="0" stop-color="#f4f4f4" />
      <stop offset="0.5" stop-color="#dee8e8" />
      <stop offset="1" stop-color="#d1cece" />
    </linearGradient>
    <linearGradient
      id="inox-gradient-2"
      data-name="inox-gradient"
      :x1="inoxW"
      :y1="doorTopHeight + 50"
      :x2="inoxW + 63.68"
      :y2="doorTopHeight + 50"
      xlink:href="#handle-gradient"
    />
    <linearGradient
      id="inox-gradient-3"
      data-name="inox-gradient"
      :x1="inoxW"
      :y1="doorTopHeight + 66.85"
      :x2="inoxW + 63.68"
      :y2="doorTopHeight + 66.85"
      xlink:href="#handle-gradient"
    />
    <linearGradient
      id="inox-gradient-4"
      data-name="inox-gradient"
      :x1="inoxW"
      :y1="doorTopHeight + 246.68"
      :x2="inoxW + 63.68"
      :y2="doorTopHeight + 246.68"
      xlink:href="#handle-gradient"
    />
    <linearGradient
      id="inox-gradient-5"
      data-name="inox-gradient"
      :x1="inoxW"
      :y1="doorTopHeight + 228.83"
      :x2="inoxW + 63.68"
      :y2="doorTopHeight + 228.83"
      xlink:href="#handle-gradient"
    />
    <g id="V39">
      <g v-if="showInox">
        <rect
          id="inox"
          class="cls-4"
          :x="doorLeftWidth + 35"
          :y="inoxY"
          width="19.67"
          :height="inoxH"
        />
        <rect
          id="inox-1"
          data-name="inox"
          class="cls-6"
          :x="doorLeftWidth + 54.48"
          :y="doorTopHeight1 + 48.09"
          :width="inoxW"
          height="1.83"
        />
        <rect
          id="inox-2"
          data-name="inox"
          class="cls-7"
          :x="doorLeftWidth + 54.48"
          :y="doorTopHeight1 + 65.78"
          :width="inoxW"
          height="1.83"
        />
        <rect
          id="inox-3"
          data-name="inox"
          class="cls-9"
          :x="doorLeftWidth + 54.48"
          :y="doorTopHeight1 + 227.92"
          :width="inoxW"
          height="1.83"
        />
        <rect
          id="inox-4"
          data-name="inox"
          class="cls-8"
          :x="doorLeftWidth + 54.48"
          :y="doorTopHeight1 + 245.77"
          :width="inoxW"
          height="1.83"
        />
      </g>
      <rect
        id="glass"
        class="cls-5"
        :x="doorLeftWidth + 39.05"
        :y="doorTopHeight1 + 57.83"
        width="11.21"
        height="179.37"
      />
    </g>
  </g>
</template>

<script>
export default {
  props: [
    'doorWidth',
    'doorHeight',
    'doorLeftWidth',
    'doorTopHeight',
    'doorGlassImage',
    'showInox',
    'showBg',
    'laminationId'
  ],
  data() {
    return {
      inoxOffset: 22.18,
      leafOffset: 6.4
    };
  },
  computed: {
    inoxY() {
      return this.showBg
        ? this.doorTopHeight + this.inoxOffset
        : this.doorTopHeight + this.leafOffset;
    },
    inoxH() {
      return this.showBg
        ? this.doorHeight - this.inoxOffset * 2
        : this.doorHeight - this.leafOffset * 2;
    },
    inoxW() {
      return this.showBg
        ? this.doorWidth - this.inoxOffset - 55.2
        : this.doorWidth - this.leafOffset - 55.2;
    },
    doorTopHeight1() {
      return this.doorTopHeight + this.doorHeight / 2 - 150;
    }
  }
};
</script>

<style lang="scss" scoped></style>
